


/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('./components/BootInput'); // Load form elements



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the body of the page. From here, you may begin adding components to
 * the application, or feel free to tweak this setup for your needs.
 */

Vue.component('example', require('./components/Example.vue'));
Vue.component('sidenav', require('./components/Sidenav.vue'));

/**
 * Register Passport vues used for Authentication.
 */

Vue.component('passport-personal-access-tokens', require('./components/passport/PersonalAccessTokens.vue'))

/** NEED THIS FOR LATEST Chrome Version 60.0.3112.101
 *
 * See the following:
 * https://github.com/pagekit/vue-resource/issues/314
 */

Vue.http.interceptors.unshift(function(request, next) {
    next(function(response) {
        if(typeof response.headers['content-type'] != 'undefined') {
            response.headers['Content-Type'] = response.headers['content-type'];
        }
    });
});




window.onload = function() {
	window.app = new Vue({
	    el: 'body',
	});
};
