'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = {
    ready: function ready() {
        console.log('Component ready.');
    }
};
if (module.exports.__esModule) module.exports = module.exports.default
;(typeof module.exports === "function"? module.exports.options: module.exports).template = "\n<div class=\"container\">\n    <div class=\"row\">\n        <div class=\"col-md-8 col-md-offset-2\">\n            <div class=\"panel panel-default\">\n                <div class=\"panel-heading\">Example Component</div>\n\n                <div class=\"panel-body\">\n                    I'm an example component!\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n"
if (module.hot) {(function () {  module.hot.accept()
  var hotAPI = require("vue-hot-reload-api")
  hotAPI.install(require("vue"), true)
  if (!hotAPI.compatible) return
  if (!module.hot.data) {
    hotAPI.createRecord("_v-33117254", module.exports)
  } else {
    hotAPI.update("_v-33117254", module.exports, (typeof module.exports === "function" ? module.exports.options : module.exports).template)
  }
})()}