// Modified from original source...
Vue.filter('reverse', function (array) {
  return array.slice().reverse()
})

Vue.component('boot-input', {
    template: '<div v-bind:class="{\'has-error\':error.length>0}" class="form-group"><label v-if="label" class="control-label">{{ label }}</label><input :type="type" class="form-control" v-model="model" :value="value" :placeholder="placeholder"/><p  v-if="error.length > 0" class="help-block">{{ error[0] }}</p></div>',
    props: ['model', 'type', 'label','placeholder'],
    data: function () {
        return {
            error: []
        }
    }
});

Vue.component('boot-textarea', {
    template: '<div v-bind:class="{\'has-error\':error.length>0}" class="form-group"><label v-if="label" class="control-label">{{ label }}</label><textarea class="form-control" rows="3" cols="30" v-model="model" :value="value" :placeholder="placeholder"/><p  v-if="error.length > 0" class="help-block">{{ error[0] }}</p></div>',
    props: ['model', 'value', 'label','placeholder'],
    data: function () {
        return {
            error: []
        }
    }
});

Vue.component('boot-select', {
    template: '<div v-bind:class="{\'has-error\':error.length>0}" class="form-group"><div><label  v-if="label" class="control-label">{{ label }}</label><select id="{{ id }}" class="form-control" v-model="model"><option v-if="placeholder" value="" :selected=" !model ? \'selected\' : null ">{{ placeholder }}</option><option v-for="(key,option) in options | orderBy order" value="{{key}}" :selected=" ((key == model) || (!model && !placeholder && $index == 0)) ? \'selected\' : null ">{{option}}</option></select><p v-if="error.length > 0" class="help-block">{{ error[0] }}</p></div></div>',
    props: ['value', 'label','options','orderByDate','placeholder','order','model','id'],
    ready: function() {
        if(!this.order) { this.order = "1"; } // must be string cuz trim() is called...
    },
    data: function () {
        return {
            error: [],
        }
    },

});

// Special one for dates
Vue.component('boot-select-date', {
    template: '<div v-bind:class="{\'has-error\':error.length>0}" class="form-group"><div><label  v-if="label" class="control-label">{{ label }}</label><select id="{{ id }}" orderdate="{{ orderdate }}" class="form-control" v-model="model"><option v-if="placeholder" value="" :selected=" !model ? \'selected\' : null ">{{ placeholder }}</option><option v-for="(key,option) in options | orderBy dateOrder order" value="{{key}}" :selected=" ((key == model) || (!model && !placeholder && $index == 0)) ? \'selected\' : null ">{{option}}</option></select><p v-if="error.length > 0" class="help-block">{{ error[0] }}</p></div></div>',
	props: ['value', 'label','options','placeholder','order','orderdate','model','id'],
	methods: {
		// Need to parse the date into pieces before making a Date object for sorting.
		parseYMDhms: function(s) {
			// super round about way to get the first element but the keys aren't the same.
			var objElement = s[Object.keys(s)[0]];

			// Format is "YYYY-mm-dd HH:mm:ss"

			var justDate = objElement.split(" ")[0];
			var justTime = objElement.split(" ")[1];

			var trueMonth = justDate.split("-")[1];
			var trueYear = justDate.split("-")[0];
			var trueDay = justDate.split("-")[2];

			var trueHour = justTime.split(":")[0];
			var trueMinute = justTime.split(":")[1];
			var trueSecond = justTime.split(":")[2];


			var day = trueDay;
			var year = trueYear;
			var month = trueMonth;
			var hour = trueHour;
			var minute = trueMinute;
			var second = trueSecond;

			return new Date(year, month, day, hour, minute, second);
		},
		dateOrder: function(x,y) {

			var a = this.parseYMDhms(x);
			var b = this.parseYMDhms(y);
			return a-b;
		},
	},
    ready: function() {
		if(!this.order) { this.order = "1"; }

		if(this.orderdate === "asc") {
			this.order = 1;
		} else {
			this.order = -1;
		}
	},
    data: function () {
        return {
			error: [],
        }
    },

});


Vue.component('boot-checkbox', {
    template: '<div v-bind:class="{\'has-error\':error.length>0}" class="form-group"><label><input type="checkbox" v-model="model" :value="value" /> {{ label }}</label><p v-if="error.length > 0" class="help-block">{{ error[0] }}</p></div>',
    props: ['model', 'value', 'label'],
    data: function () {
        return {
            error: []
        }
    }
});
